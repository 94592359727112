
import logo from "./logo.png"
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { useState, useEffect } from "react";
import hmt from "./assets/hmt.jpg"
import plants from "./assets/plants.jpeg"
import berrie from "./assets/berrie.jpeg"
import bams from "./assets/bams.jpeg"
import grogu from "./assets/grogu.jpg"
import bonGrok from "./assets/bobgrok.png"
import babyBob from "./assets/babyBob.png"
import privatePepe from "./assets/privatepepe.jpeg"
import bobminer from "./assets/bobminer.png"

function App() {
  const [page,setPage] = useState(0);
  let url = "https://cypherex.online/?page="

  useEffect(() => {
    // Get the page parameter from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const pageParam = urlParams.get('page');

    // Convert the pageParam to a number and update the page state
    const pageNumber = parseInt(pageParam, 10) || 0;
    setPage(pageNumber);
  }, []);



  return (
   <>
   <div className='container'>
   <center>
<img src={logo} alt="logo" className='img-fluid' width={300}/> 
<h1 style={{fontSize:"120px"}}>CYPHEREX AUDIT</h1>
<h3 style={{color:"#00FF00"}}><a href={url+0}><button className="btn btn-lg btn-success">DASHBOARD </button></a> <a href={url+-1}><button className="btn btn-lg btn-success">PUBLISH AUDIT </button> </a> <a href={url+-2}><button className="btn btn-lg btn-success">SUBMIT KYC</button></a></h3>
</center>
{page === 0? <>
  <table className='table text-center'>
  <thead>
    <tr>
        <th>Time</th>
        <th>Type</th>
        <th>Name</th>
        <th>Category</th>
        <th>Score</th>
        <th>Audit</th>
      
    </tr>


  </thead>

  <tbody>

  <tr>
   
   <td>23 Dec 2023</td>
   <td>DeFi</td>
   <td>BobMiner</td>
   <td>High Risk</td>
   <td>70/100</td>
   <td ><a href={url+9}><button className="btn  btn-success">Click Here</button></a></td>
 
</tr>

  <tr>
   
   <td>19 Dec 2023</td>
   <td>Meme Token</td>
   <td>PRIVATE PEPE</td>
   <td>Low Risk</td>
   <td>97/100</td>
   <td ><a href={url+8}><button className="btn  btn-success">Click Here</button></a></td>
 
</tr>

  <tr>
   
   <td>17 Dec 2023</td>
   <td>Meme Token</td>
   <td>BonGrok</td>
   <td>Low Risk</td>
   <td>85/100</td>
   <td ><a href={url+6}><button className="btn  btn-success">Click Here</button></a></td>
 
</tr>

  <tr>
   
   <td>17 Dec 2023</td>
   <td>Meme Token</td>
   <td>BabyBob</td>
   <td>Low Risk</td>
   <td>90/100</td>
   <td ><a href={url+7}><button className="btn  btn-success">Click Here</button></a></td>
 
</tr>
  <tr>
   
        <td>5 Dec 2023</td>
        <td>Meme Token</td>
        <td>HOPEMONEY TOKEN</td>
        <td>Low Risk</td>
        <td>5/100</td>
        <td ><a href={url+1}><button className="btn  btn-success">Click Here</button></a></td>
      
    </tr>

    <tr>
        <td>October 13, 2023</td>
        <td>Meme Token</td>
        <td>BAMS</td>
        <td>Low Risk</td>
        <td>100/100</td>
        <td ><a href={url+4}><button className="btn  btn-success">Click Here</button></a></td>
      
    </tr>

    <tr>
        <td>October 8, 2023</td>
        <td>Meme Token</td>
        <td>BERRIE</td>
        <td>Low Risk</td>
        <td>99/100</td>
        <td ><a href={url+3}><button className="btn  btn-success">Click Here</button></a></td>
      
    </tr>


    <tr>
        <td>September 27, 2023</td>
        <td>Meme Token</td>
        <td>Planet2049</td>
        <td>Low Risk</td>
        <td>85/100</td>
        <td ><a href={url+2}><button className="btn  btn-success">Click Here</button></a></td>
      
    </tr>

    <tr>
        <td>August 18, 2023</td>
        <td>Meme Token</td>
        <td>GROGU</td>
        <td>Low Risk</td>
        <td>80/100</td>
        <td><a href={url+5}><button className="btn  btn-success">Click Here</button></a></td>
      
    </tr>


  </tbody>
</table>

</> : ""}

{page === 1? <>

<h4 className="text-white">
 <center> <img src={hmt} alt="logo" className="img-fluid"/></center>
<table className="table">
        <tbody>
          <tr>
            <td>AUDITED BY</td>
            <td>Cypherex6009</td>
          </tr>
          <tr>
            <td>WEBSITE</td>
            <td><a href="https://hmtoken.online/" target="_blank" rel="noopener noreferrer">https://hmtoken.online/</a></td>
          </tr>
          <tr>
            <td>TWITTER</td>
            <td><a href="https://twitter.com/Hmt_token" target="_blank" rel="noopener noreferrer">https://twitter.com/Hmt_token</a></td>
          </tr>
          <tr>
            <td>TELEGRAM</td>
            <td><a href="https://t.me/hmttoken" target="_blank" rel="noopener noreferrer">https://t.me/hmttoken</a></td>
          </tr>
          <tr>
            <td>CATEGORY</td>
            <td>LOW RISK</td>
          </tr>
          <tr>
            <td>CHAIN</td>
            <td>BSC</td>
          </tr>
          <tr>
            <td>CONTRACT</td>
            <td><a href="https://bscscan.com/address/0xf3d6cf3f028032b8a82c0Fa5091f1E31aBa61116" target="_blank" rel="noopener noreferrer">0xf3d6cf3f028032b8a82c0Fa5091f1E31aBa61116</a></td>
          </tr>
          <tr>
            <td>SCORE</td>
            <td>2/100</td>
          </tr>
          <tr>
            <td>BACKDOOR</td>
            <td style={{color:"red"}}>YES (HONEYPOT)</td>
          </tr>
        </tbody>
      </table>



</h4>
<hr />
<h1><span className="fw-bold" style={{fontSize:"64px"}}>Disclaimer</span></h1>
<h5 className="text-white">

This audit exclusively pertains to the Smart Contract code located at the specified address. Cypherex operates as a third-party auditing company, specializing in audits tailored to client requests. As a professional auditing firm, our thorough examination focuses on identifying and addressing potential vulnerabilities, backdoors, and any elements indicative of scam scripts within the contract.
Therefore:
We are not financial advisors, and we do not form partnerships with the contract owners.
Operations and website administration are entirely the responsibility of the client.
We do not exert influence over client operations, including changes to the website or the closure of withdrawal functions. Clients retain the autonomy to make these decisions through the contract.
Any concerns regarding the projects themselves should be addressed directly to the project owners and not through CYPHEREX.
Investors are not obligated, coerced, or influenced in any way to invest in projects audited by CYPHEREX.
We do not assume responsibility for your funds or guarantee profits.
We strongly recommend that investors conduct their own research and acquire cryptocurrency experience before making investment decisions.
To report any scams, malpractices, or irregularities, please send a message via Telegram to @CYPHEREX6009 for the purpose of blacklisting

</h5>

<h1 className="fw-bold" style={{fontSize:"64px"}}>Summary</h1>
<h5 className="text-white">
This smart contract has been reviewed by cyhperex6009 and found backdoor code. There is malicious code that allows the owner (or other) to withdraw all funds from the smart contract.
The owner can pause the sell so the buyer won't able to sell their tokens. 

</h5>


<h1><span className="fw-bold" style={{fontSize:"64px"}}>Issues and Vulnerabilities</span></h1>
<table className="table">
  <tbody>
<tr>
  <td>CRITICAL ISSUES</td>
  <td>(Critical, high severity)</td>
  <td>1</td>
</tr>

<tr>
  <td>HIGH ISSUES</td>
  <td>(High, medium severity)</td>
  <td>1</td>
</tr>

<tr>
  <td>ERROR WARNINGS</td>
  <td>(Medium, low severity)</td>
  <td>1</td>
</tr>

<tr>
  <td>OPTIMIZATION</td>
  <td>(Low severity)</td>
  <td>1</td>
</tr>

<tr>
  <td>RECOMMENDATIONS</td>
  <td>(Very low severity)</td>
  <td>0</td>
</tr>
  </tbody>
</table>


<h1><span className="fw-bold" style={{fontSize:"64px"}}>Contract Overview</span></h1>
<h5 className="text-white">

<table className="table">
     
     <tbody>
       <tr>
         <td>Minting</td>
         <td>
           <span style={{ color: 'green' }}>🔴</span>
         </td>
         <td>
           <span style={{ color: 'red' }}>YES</span>
         </td>
       </tr>
       <tr>
         <td>Honeypot</td>
         <td>
           <span style={{ color: 'green' }}>🔴</span>
         </td>
         <td>
           <span style={{ color: 'red' }}>YES</span>
         </td>
       </tr>
       <tr>
         <td>Enable Trading</td>
         <td>
           <span style={{ color: 'green' }}>🟢</span>
         </td>
         <td>
           <span style={{ color: 'green' }}>YES</span>
         </td>
       </tr>
       <tr>
         <td>Pause Trading</td>
         <td>
           <span style={{ color: 'green' }}>🔴</span>
         </td>
         <td>
           <span style={{ color: 'red' }}>YES</span>
         </td>
       </tr>
       <tr>
         <td>Max Wallet</td>
         <td>
           <span style={{ color: 'green' }}>🟢</span>
         </td>
         <td>
           <span style={{ color: 'green' }}>NO</span>
         </td>
       </tr>
       <tr>
         <td>Blacklist</td>
         <td>
           <span style={{ color: 'green' }}>🔴</span>
         </td>
         <td>
           <span style={{ color: 'red' }}>YES</span>
         </td>
       </tr>
       <tr>
         <td>Proxy</td>
         <td>
           <span style={{ color: 'green' }}>🟢</span>
         </td>
         <td>
           <span style={{ color: 'green' }}>NO</span>
         </td>
       </tr>
       <tr>
         <td>Hidden Owner</td>
         <td>
           <span style={{ color: 'green' }}>🟢</span>
         </td>
         <td>
           <span style={{ color: 'green' }}>NO</span>
         </td>
       </tr>
       <tr>
         <td>Max Tx</td>
         <td>
           <span style={{ color: 'green' }}>🟢</span>
         </td>
         <td>
           <span style={{ color: 'green' }}>NO</span>
         </td>
       </tr>
       <tr>
         <td>Antibot System</td>
         <td>
           <span style={{ color: 'green' }}>🟢</span>
         </td>
         <td>
           <span style={{ color: 'green' }}>NO</span>
         </td>
       </tr>
     </tbody>
   </table>
</h5>


<h1 className="fw-bold" style={{fontSize:"64px"}}>Token Information</h1>

<h5 className="text-white">

<table className="table">
        <tbody>
          <tr>
            <td>Token Name</td>
            <td>HOPEMONEY TOKEN</td>
          </tr>
          <tr>
            <td>Token Symbol</td>
            <td>HMT</td>
          </tr>
          <tr>
            <td>Token Decimal</td>
            <td>18</td>
          </tr>
          <tr>
            <td>Blockchain</td>
            <td>BSC</td>
          </tr>
          <tr>
            <td>Token Supply</td>
            <td>100,000,000</td>
          </tr>
          <tr>
            <td>Contract Address</td>
            <td><a href="https://bscscan.com/address/0xf3d6cf3f028032b8a82c0Fa5091f1E31aBa61116" target="_blank" rel="noopener noreferrer">0xf3d6cf3f028032b8a82c0Fa5091f1E31aBa61116</a></td>
          </tr>
          <tr>
            <td>Contract Language</td>
            <td>Solidity</td>
          </tr>
          <tr>
            <td>Current Buy Fee</td>
            <td>0%</td>
          </tr>
          <tr>
            <td>Current Sell Fee</td>
            <td>0%</td>
          </tr>
          <tr>
            <td>Current Transfer Fee</td>
            <td>0%</td>
          </tr>
        </tbody>
      </table>
</h5>




</> : <></>}




{page === 2? <>

<h4 className="text-white">
<center> <img src={plants} alt="logo" className="img-fluid"/></center>
<table className="table">
        <tbody>
          <tr>
            <td>AUDITED BY</td>
            <td>Cypherex6009</td>
          </tr>
          <tr>
            <td>WEBSITE</td>
            <td><a href="https://planet2490.pro/" target="_blank" rel="noopener noreferrer">https://planet2490.pro/</a></td>
          </tr>
          <tr>
            <td>TWITTER</td>
            <td><a href="https://twitter.com/planet2049" target="_blank" rel="noopener noreferrer">https://twitter.com/planet2049</a></td>
          </tr>
          <tr>
            <td>TELEGRAM</td>
            <td><a href="https://t.me/planet2049" target="_blank" rel="noopener noreferrer">https://t.me/planet2049</a></td>
          </tr>
          <tr>
            <td>CATEGORY</td>
            <td>LOW RISK</td>
          </tr>
          <tr>
            <td>CHAIN</td>
            <td>BSC</td>
          </tr>
          <tr>
            <td>CONTRACT</td>
            <td><a href="https://bscscan.com/address/0x7FEd022F8dB486A77D798a9f0B285a4a5242293a" target="_blank" rel="noopener noreferrer">0x7FEd022F8dB486A77D798a9f0B285a4a5242293a</a></td>
          </tr>
          <tr>
            <td>SCORE</td>
            <td>85/100</td>
          </tr>
          <tr>
            <td>BACKDOOR</td>
            <td>NOT FOUND</td>
          </tr>
        </tbody>
      </table>



</h4>
<hr />
<h1><span className="fw-bold" style={{fontSize:"64px"}}>Disclaimer</span></h1>
<h5 className="text-white">

This audit exclusively pertains to the Smart Contract code located at the specified address. Cypherex operates as a third-party auditing company, specializing in audits tailored to client requests. As a professional auditing firm, our thorough examination focuses on identifying and addressing potential vulnerabilities, backdoors, and any elements indicative of scam scripts within the contract.
Therefore:
We are not financial advisors, and we do not form partnerships with the contract owners.
Operations and website administration are entirely the responsibility of the client.
We do not exert influence over client operations, including changes to the website or the closure of withdrawal functions. Clients retain the autonomy to make these decisions through the contract.
Any concerns regarding the projects themselves should be addressed directly to the project owners and not through CYPHEREX.
Investors are not obligated, coerced, or influenced in any way to invest in projects audited by CYPHEREX.
We do not assume responsibility for your funds or guarantee profits.
We strongly recommend that investors conduct their own research and acquire cryptocurrency experience before making investment decisions.
To report any scams, malpractices, or irregularities, please send a message via Telegram to @CYPHEREX6009 for the purpose of blacklisting

</h5>

<h1 className="fw-bold" style={{fontSize:"64px"}}>Summary</h1>
<h5 className="text-white">
This smart contract has been reviewed by cyhperex6009 and found no backdoor code. There is no malicious code that allows the owner (or other) to withdraw all funds from the smart contract.
The owner can enable the trade and not able to pause the buy and sell again. All the Code is Safe. 

</h5>


<h1><span className="fw-bold" style={{fontSize:"64px"}}>Issues and Vulnerabilities</span></h1>
<table className="table">
  <tbody>
<tr>
  <td>CRITICAL ISSUES</td>
  <td>(Critical, high severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>HIGH ISSUES</td>
  <td>(High, medium severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>ERROR WARNINGS</td>
  <td>(Medium, low severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>OPTIMIZATION</td>
  <td>(Low severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>RECOMMENDATIONS</td>
  <td>(Very low severity)</td>
  <td>0</td>
</tr>
  </tbody>
</table>


<h1><span className="fw-bold" style={{fontSize:"64px"}}>Contract Overview</span></h1>
<h5 className="text-white">
<table className="table">
     
        <tbody>
          <tr>
            <td>Minting</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Honeypot</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Enable Trading</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>YES</span>
            </td>
          </tr>
          <tr>
            <td>Pause Trading</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Max Wallet</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Blacklist</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Proxy</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Hidden Owner</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Max Tx</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Antibot System</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
        </tbody>
      </table>
</h5>


<h1 className="fw-bold" style={{fontSize:"64px"}}>Token Information</h1>

<h5 className="text-white">

<table className="table">
        <tbody>
          <tr>
            <td>Token Name</td>
            <td>Planet2049</td>
          </tr>
          <tr>
            <td>Token Symbol</td>
            <td>PLN</td>
          </tr>
          <tr>
            <td>Token Decimal</td>
            <td>18</td>
          </tr>
          <tr>
            <td>Blockchain</td>
            <td>BSC</td>
          </tr>
          <tr>
            <td>Token Supply</td>
            <td>1,000,000,000</td>
          </tr>
          <tr>
            <td>Contract Address</td>
            <td><a href="https://bscscan.com/address/0x7FEd022F8dB486A77D798a9f0B285a4a5242293a" target="_blank" rel="noopener noreferrer">0x7FEd022F8dB486A77D798a9f0B285a4a5242293a</a></td>
          </tr>
          <tr>
            <td>Contract Language</td>
            <td>Solidity</td>
          </tr>
          <tr>
            <td>Current Buy Fee</td>
            <td>3%</td>
          </tr>
          <tr>
            <td>Current Sell Fee</td>
            <td>3%</td>
          </tr>
          <tr>
            <td>Current Transfer Fee</td>
            <td>3%</td>
          </tr>
        </tbody>
      </table>
</h5>

</> : <></>}


{page === 3? <>
  <h4 className="text-white">
  <center> <img src={berrie} alt="logo" className="img-fluid"/></center>
  <table className="table">
        <tbody>
          <tr>
            <td>AUDITED BY</td>
            <td>Cypherex6009</td>
          </tr>
          <tr>
            <td>WEBSITE</td>
            <td><a href="https://berrie.org/" target="_blank" rel="noopener noreferrer">https://berrie.org/</a></td>
          </tr>
          <tr>
            <td>TWITTER</td>
            <td><a href="https://twitter.com/BerrieOrg" target="_blank" rel="noopener noreferrer">https://twitter.com/BerrieOrg</a></td>
          </tr>
          <tr>
            <td>TELEGRAM</td>
            <td><a href="https://t.me/BerrieFarm" target="_blank" rel="noopener noreferrer">https://t.me/BerrieFarm</a></td>
          </tr>
          <tr>
            <td>CATEGORY</td>
            <td>LOW RISK</td>
          </tr>
          <tr>
            <td>CHAIN</td>
            <td>ETHEREUM</td>
          </tr>
          <tr>
            <td>CONTRACT</td>
            <td><a href="https://etherscan.io/address/0x37653126a22FE12C527197CE2016F74Cd74f3c77" target="_blank" rel="noopener noreferrer">0x37653126a22FE12C527197CE2016F74Cd74f3c77</a></td>
          </tr>
          <tr>
            <td>SCORE</td>
            <td>99/100</td>
          </tr>
          <tr>
            <td>BACKDOOR</td>
            <td>NOT FOUND</td>
          </tr>
        </tbody>
      </table>



</h4>
<hr />
<h1><span className="fw-bold" style={{fontSize:"64px"}}>Disclaimer</span></h1>
<h5 className="text-white">

This audit exclusively pertains to the Smart Contract code located at the specified address. Cypherex operates as a third-party auditing company, specializing in audits tailored to client requests. As a professional auditing firm, our thorough examination focuses on identifying and addressing potential vulnerabilities, backdoors, and any elements indicative of scam scripts within the contract.
Therefore:
We are not financial advisors, and we do not form partnerships with the contract owners.
Operations and website administration are entirely the responsibility of the client.
We do not exert influence over client operations, including changes to the website or the closure of withdrawal functions. Clients retain the autonomy to make these decisions through the contract.
Any concerns regarding the projects themselves should be addressed directly to the project owners and not through CYPHEREX.
Investors are not obligated, coerced, or influenced in any way to invest in projects audited by CYPHEREX.
We do not assume responsibility for your funds or guarantee profits.
We strongly recommend that investors conduct their own research and acquire cryptocurrency experience before making investment decisions.
To report any scams, malpractices, or irregularities, please send a message via Telegram to @CYPHEREX6009 for the purpose of blacklisting

</h5>

<h1 className="fw-bold">Summary</h1>
<h5 className="text-white">
This smart contract has been reviewed by cyhperex6009 and found no backdoor code. There is no malicious code that allows the owner (or other) to withdraw all funds from the smart contract.
The owner can enable the trade and not able to pause the buy and sell again. All the Code is Safe. 

</h5>


<h1><span className="fw-bold" style={{fontSize:"64px"}}>Issues and Vulnerabilities</span></h1>
<table className="table">
  <tbody>
<tr>
  <td>CRITICAL ISSUES</td>
  <td>(Critical, high severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>HIGH ISSUES</td>
  <td>(High, medium severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>ERROR WARNINGS</td>
  <td>(Medium, low severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>OPTIMIZATION</td>
  <td>(Low severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>RECOMMENDATIONS</td>
  <td>(Very low severity)</td>
  <td>0</td>
</tr>
  </tbody>
</table>


<h1><span className="fw-bold" style={{fontSize:"64px"}}>Contract Overview</span></h1>
<h5 className="text-white">

<table className="table">
     
        <tbody>
          <tr>
            <td>Minting</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Honeypot</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Enable Trading</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>YES</span>
            </td>
          </tr>
          <tr>
            <td>Pause Trading</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Max Wallet</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Blacklist</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Proxy</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Hidden Owner</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Max Tx</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Antibot System</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
        </tbody>
      </table>
</h5>


<h1 className="fw-bold" style={{fontSize:"64px"}}>Token Information</h1>

<h5 className="text-white">

<table className="table">
        <tbody>
          <tr>
            <td>Token Name</td>
            <td>BERRIE</td>
          </tr>
          <tr>
            <td>Token Symbol</td>
            <td>BERRIE</td>
          </tr>
          <tr>
            <td>Token Decimal</td>
            <td>18</td>
          </tr>
          <tr>
            <td>Blockchain</td>
            <td>ETH</td>
          </tr>
          <tr>
            <td>Token Supply</td>
            <td>1,000,000</td>
          </tr>
          <tr>
            <td>Contract Address</td>
            <td><a href="https://etherscan.io/address/0x37653126a22FE12C527197CE2016F74Cd74f3c77" target="_blank" rel="noopener noreferrer">0x37653126a22FE12C527197CE2016F74Cd74f3c77</a></td>
          </tr>
          <tr>
            <td>Contract Language</td>
            <td>Solidity</td>
          </tr>
          <tr>
            <td>Current Buy Fee</td>
            <td>0%</td>
          </tr>
          <tr>
            <td>Current Sell Fee</td>
            <td>0%</td>
          </tr>
          <tr>
            <td>Current Transfer Fee</td>
            <td>0%</td>
          </tr>
        </tbody>
      </table>
</h5>

</> : ""}




{page === 4? <>
  <h4 className="text-white">
  <center> <img src={bams} alt="logo" className="img-fluid"/></center>
  <table className="table">
        <tbody>
          <tr>
            <td>AUDITED BY</td>
            <td>Cypherex6009</td>
          </tr>
          <tr>
            <td>WEBSITE</td>
            <td><a href="https://bams.monster/" target="_blank" rel="noopener noreferrer">https://bams.monster/</a></td>
          </tr>
          <tr>
            <td>TWITTER</td>
            <td><a href="https://twitter.com/bamsmonster" target="_blank" rel="noopener noreferrer">https://twitter.com/bamsmonster</a></td>
          </tr>
          <tr>
            <td>TELEGRAM</td>
            <td><a href="https://t.me/bamsmonster" target="_blank" rel="noopener noreferrer">https://t.me/bamsmonster</a></td>
          </tr>
          <tr>
            <td>CATEGORY</td>
            <td>LOW RISK</td>
          </tr>
          <tr>
            <td>CHAIN</td>
            <td>ETHEREUM</td>
          </tr>
          <tr>
            <td>CONTRACT</td>
            <td><a href="https://etherscan.io/address/0x743276C227f0F9004C018eE8454247FB4f2eFe16" target="_blank" rel="noopener noreferrer">0x743276C227f0F9004C018eE8454247FB4f2eFe16</a></td>
          </tr>
          <tr>
            <td>SCORE</td>
            <td>100/100</td>
          </tr>
          <tr>
            <td>BACKDOOR</td>
            <td>NOT FOUND</td>
          </tr>
        </tbody>
      </table>



</h4>
<hr />
<h1><span className="fw-bold" style={{fontSize:"64px"}}>Disclaimer</span></h1>
<h5 className="text-white">

This audit exclusively pertains to the Smart Contract code located at the specified address. Cypherex operates as a third-party auditing company, specializing in audits tailored to client requests. As a professional auditing firm, our thorough examination focuses on identifying and addressing potential vulnerabilities, backdoors, and any elements indicative of scam scripts within the contract.
Therefore:
We are not financial advisors, and we do not form partnerships with the contract owners.
Operations and website administration are entirely the responsibility of the client.
We do not exert influence over client operations, including changes to the website or the closure of withdrawal functions. Clients retain the autonomy to make these decisions through the contract.
Any concerns regarding the projects themselves should be addressed directly to the project owners and not through CYPHEREX.
Investors are not obligated, coerced, or influenced in any way to invest in projects audited by CYPHEREX.
We do not assume responsibility for your funds or guarantee profits.
We strongly recommend that investors conduct their own research and acquire cryptocurrency experience before making investment decisions.
To report any scams, malpractices, or irregularities, please send a message via Telegram to @CYPHEREX6009 for the purpose of blacklisting

</h5>

<h1 className="fw-bold" style={{fontSize:"64px"}}>Summary</h1>
<h5 className="text-white">
This smart contract has been reviewed by cyhperex6009 and found no backdoor code. There is no malicious code that allows the owner (or other) to withdraw all funds from the smart contract.
The owner can enable the trade and not able to pause the buy and sell again. All the Code is Safe. 

</h5>


<h1><span className="fw-bold" style={{fontSize:"64px"}}>Issues and Vulnerabilities</span></h1>
<table className="table">
  <tbody>
<tr>
  <td>CRITICAL ISSUES</td>
  <td>(Critical, high severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>HIGH ISSUES</td>
  <td>(High, medium severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>ERROR WARNINGS</td>
  <td>(Medium, low severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>OPTIMIZATION</td>
  <td>(Low severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>RECOMMENDATIONS</td>
  <td>(Very low severity)</td>
  <td>0</td>
</tr>
  </tbody>
</table>


<h1><span className="fw-bold" style={{fontSize:"64px"}}>Contract Overview</span></h1>
<h5 className="text-white">

<table className="table">
     
        <tbody>
          <tr>
            <td>Minting</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Honeypot</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Enable Trading</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Pause Trading</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Max Wallet</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Blacklist</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Proxy</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Hidden Owner</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Max Tx</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Antibot System</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
        </tbody>
      </table>

</h5>


<h1 className="fw-bold" style={{fontSize:"64px"}}>Token Information</h1>

<h5 className="text-white">

<table className="table">
        <tbody>
          <tr>
            <td>Token Name</td>
            <td>BAMS</td>
          </tr>
          <tr>
            <td>Token Symbol</td>
            <td>BAMS</td>
          </tr>
          <tr>
            <td>Token Decimal</td>
            <td>18</td>
          </tr>
          <tr>
            <td>Blockchain</td>
            <td>ETH</td>
          </tr>
          <tr>
            <td>Token Supply</td>
            <td>1,000,000,000</td>
          </tr>
          <tr>
            <td>Contract Address</td>
            <td><a href="https://etherscan.io/address/0x743276C227f0F9004C018eE8454247FB4f2eFe16" target="_blank" rel="noopener noreferrer">0x743276C227f0F9004C018eE8454247FB4f2eFe16</a></td>
          </tr>
          <tr>
            <td>Contract Language</td>
            <td>Solidity</td>
          </tr>
          <tr>
            <td>Current Buy Fee</td>
            <td>0%</td>
          </tr>
          <tr>
            <td>Current Sell Fee</td>
            <td>0%</td>
          </tr>
          <tr>
            <td>Current Transfer Fee</td>
            <td>0%</td>
          </tr>
        </tbody>
      </table>
</h5>

</> : ""}




{page === 5? <>
  <h4 className="text-white">
  <center> <img src={grogu} alt="logo" className="img-fluid" width={300}/></center>
  <table className="table">
        <tbody>
          <tr>
            <td>AUDITED BY</td>
            <td>Cypherex6009</td>
          </tr>
          <tr>
            <td>WEBSITE</td>
            <td><a href="https://grogutoken.net/" target="_blank" rel="noopener noreferrer">https://grogutoken.net/</a></td>
          </tr>
          <tr>
            <td>TWITTER</td>
            <td><a href="https://twitter.com/Groguglobal" target="_blank" rel="noopener noreferrer">https://twitter.com/Groguglobal</a></td>
          </tr>
          <tr>
            <td>TELEGRAM</td>
            <td><a href="https://t.me/grogutokenglobal" target="_blank" rel="noopener noreferrer">https://t.me/grogutokenglobal</a></td>
          </tr>
          <tr>
            <td>CATEGORY</td>
            <td>LOW RISK</td>
          </tr>
          <tr>
            <td>CHAIN</td>
            <td>BSC</td>
          </tr>
          <tr>
            <td>CONTRACT</td>
            <td><a href="https://bscscan.com/address/0x3D5BB18780d07C6E2DF9A21e56C45096127eB5d9" target="_blank" rel="noopener noreferrer">0x3D5BB18780d07C6E2DF9A21e56C45096127eB5d9</a></td>
          </tr>
          <tr>
            <td>SCORE</td>
            <td>80/100</td>
          </tr>
          <tr>
            <td>BACKDOOR</td>
            <td>NOT FOUND</td>
          </tr>
        </tbody>
      </table>



</h4>
<hr />
<h1><span className="fw-bold" style={{fontSize:"64px"}}>Disclaimer</span></h1>
<h5 className="text-white">

This audit exclusively pertains to the Smart Contract code located at the specified address. Cypherex operates as a third-party auditing company, specializing in audits tailored to client requests. As a professional auditing firm, our thorough examination focuses on identifying and addressing potential vulnerabilities, backdoors, and any elements indicative of scam scripts within the contract.
Therefore:
We are not financial advisors, and we do not form partnerships with the contract owners.
Operations and website administration are entirely the responsibility of the client.
We do not exert influence over client operations, including changes to the website or the closure of withdrawal functions. Clients retain the autonomy to make these decisions through the contract.
Any concerns regarding the projects themselves should be addressed directly to the project owners and not through CYPHEREX.
Investors are not obligated, coerced, or influenced in any way to invest in projects audited by CYPHEREX.
We do not assume responsibility for your funds or guarantee profits.
We strongly recommend that investors conduct their own research and acquire cryptocurrency experience before making investment decisions.
To report any scams, malpractices, or irregularities, please send a message via Telegram to @CYPHEREX6009 for the purpose of blacklisting

</h5>

<h1 className="fw-bold" style={{fontSize:"64px"}}>Summary</h1>
<h5 className="text-white">
This smart contract has been reviewed by cyhperex6009 and found no backdoor code. There is no malicious code that allows the owner (or other) to withdraw all funds from the smart contract.
The owner can enable the trade and not able to pause the buy and sell again. All the Code is Safe. 

</h5>


<h1><span className="fw-bold" style={{fontSize:"64px"}}>Issues and Vulnerabilities</span></h1>
<table className="table">
  <tbody>
<tr>
  <td>CRITICAL ISSUES</td>
  <td>(Critical, high severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>HIGH ISSUES</td>
  <td>(High, medium severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>ERROR WARNINGS</td>
  <td>(Medium, low severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>OPTIMIZATION</td>
  <td>(Low severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>RECOMMENDATIONS</td>
  <td>(Very low severity)</td>
  <td>0</td>
</tr>
  </tbody>
</table>


<h1><span className="fw-bold" style={{fontSize:"64px"}}>Contract Overview</span></h1>
<h5 className="text-white">
<table className="table">
  <tbody>
  <tr>
            <td>Minting</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Honeypot</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Enable Trading</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Pause Trading</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Max Wallet</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Blacklist</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Proxy</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Hidden Owner</td>
            <td>
              <span style={{ color: 'green' }}>🔴</span>
            </td>
            <td>
              <span style={{ color: 'red' }}>YES</span>
            </td>
          </tr>
          <tr>
            <td>Max Tx</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Antibot System</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
  </tbody>
</table>
</h5>


<h1 className="fw-bold" style={{fontSize:"64px"}}>Token Information</h1>

<h5 className="text-white">

<table className="table">
        <tbody>
          <tr>
            <td>Token Name</td>
            <td>GROGU</td>
          </tr>
          <tr>
            <td>Token Symbol</td>
            <td>GRG</td>
          </tr>
          <tr>
            <td>Token Decimal</td>
            <td>18</td>
          </tr>
          <tr>
            <td>Blockchain</td>
            <td>BSC</td>
          </tr>
          <tr>
            <td>Token Supply</td>
            <td>1,000,000,000</td>
          </tr>
          <tr>
            <td>Contract Address</td>
            <td><a href="https://bscscan.com/address/0x3D5BB18780d07C6E2DF9A21e56C45096127eB5d9" target="_blank" rel="noopener noreferrer">0x3D5BB18780d07C6E2DF9A21e56C45096127eB5d9</a></td>
          </tr>
          <tr>
            <td>Contract Language</td>
            <td>Solidity</td>
          </tr>
          <tr>
            <td>Current Buy Fee</td>
            <td>8%</td>
          </tr>
          <tr>
            <td>Current Sell Fee</td>
            <td>8%</td>
          </tr>
          <tr>
            <td>Current Transfer Fee</td>
            <td>8%</td>
          </tr>
        </tbody>
      </table>
</h5>

</> : ""}






 {page === 6? <>
  <h4 className="text-white">
  <center> <img src={bonGrok} alt="logo" className="img-fluid" width={300}/></center>
  <table className="table">
        <tbody>
          <tr>
            <td>AUDITED BY</td>
            <td>Cypherex6009</td>
          </tr>
          <tr>
            <td>WEBSITE</td>
            <td><a href="cypherex.online" target="_blank" rel="noopener noreferrer">No-Site</a></td>
          </tr>
          <tr>
            <td>TWITTER</td>
            <td><a href="https://x.com/bongrokbsc?s=21" target="_blank" rel="noopener noreferrer">https://x.com/bongrokbsc?s=21</a></td>
          </tr>
          <tr>
            <td>TELEGRAM</td>
            <td><a href="https://t.me/BonGrok_channel" target="_blank" rel="noopener noreferrer">https://t.me/BonGrok_channel</a></td>
          </tr>
          <tr>
            <td>CATEGORY</td>
            <td>LOW RISK</td>
          </tr>
          <tr>
            <td>CHAIN</td>
            <td>BSC</td>
          </tr>
          <tr>
            <td>CONTRACT</td>
            <td><a href="https://bscscan.com/address/0xEB76995AcBb7EcB7255cDcfFc0c991ebe7cd3A5a" target="_blank" rel="noopener noreferrer">0xEB76995AcBb7EcB7255cDcfFc0c991ebe7cd3A5a</a></td>
          </tr>
          <tr>
            <td>SCORE</td>
            <td>85/100</td>
          </tr>
          <tr>
            <td>BACKDOOR</td>
            <td>NOT FOUND</td>
          </tr>
        </tbody>
      </table>



</h4>
<hr />
<h1><span className="fw-bold" style={{fontSize:"64px"}}>Disclaimer</span></h1>
<h5 className="text-white">

This audit exclusively pertains to the Smart Contract code located at the specified address. Cypherex operates as a third-party auditing company, specializing in audits tailored to client requests. As a professional auditing firm, our thorough examination focuses on identifying and addressing potential vulnerabilities, backdoors, and any elements indicative of scam scripts within the contract.
Therefore:
We are not financial advisors, and we do not form partnerships with the contract owners.
Operations and website administration are entirely the responsibility of the client.
We do not exert influence over client operations, including changes to the website or the closure of withdrawal functions. Clients retain the autonomy to make these decisions through the contract.
Any concerns regarding the projects themselves should be addressed directly to the project owners and not through CYPHEREX.
Investors are not obligated, coerced, or influenced in any way to invest in projects audited by CYPHEREX.
We do not assume responsibility for your funds or guarantee profits.
We strongly recommend that investors conduct their own research and acquire cryptocurrency experience before making investment decisions.
To report any scams, malpractices, or irregularities, please send a message via Telegram to @CYPHEREX6009 for the purpose of blacklisting

</h5>

<h1 className="fw-bold" style={{fontSize:"64px"}}>Summary</h1>
<h5 className="text-white">
This smart contract has been reviewed by cyhperex6009 and found no backdoor code. There is no malicious code that allows the owner (or other) to withdraw all funds from the smart contract.
The owner can enable the trade and not able to pause the buy and sell again. All the Code is Safe. 

</h5>


<h1><span className="fw-bold" style={{fontSize:"64px"}}>Issues and Vulnerabilities</span></h1>
<table className="table">
  <tbody>
<tr>
  <td>CRITICAL ISSUES</td>
  <td>(Critical, high severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>HIGH ISSUES</td>
  <td>(High, medium severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>ERROR WARNINGS</td>
  <td>(Medium, low severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>OPTIMIZATION</td>
  <td>(Low severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>RECOMMENDATIONS</td>
  <td>(Very low severity)</td>
  <td>0</td>
</tr>
  </tbody>
</table>


<h1><span className="fw-bold" style={{fontSize:"64px"}}>Contract Overview</span></h1>
<h5 className="text-white">
<table className="table">
  <tbody>
  <tr>
            <td>Minting</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Honeypot</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Enable Trading</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Pause Trading</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Max Wallet</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Blacklist</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Proxy</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Hidden Owner</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Max Tx</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Antibot System</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
  </tbody>
</table>
</h5>


<h1 className="fw-bold" style={{fontSize:"64px"}}>Token Information</h1>

<h5 className="text-white">

<table className="table">
        <tbody>
          <tr>
            <td>Token Name</td>
            <td>BonGrok</td>
          </tr>
          <tr>
            <td>Token Symbol</td>
            <td>BonGrok</td>
          </tr>
          <tr>
            <td>Token Decimal</td>
            <td>18</td>
          </tr>
          <tr>
            <td>Blockchain</td>
            <td>BSC</td>
          </tr>
          <tr>
            <td>Token Supply</td>
            <td>420000000000</td>
          </tr>
          <tr>
            <td>Contract Address</td>
            <td><a href="https://bscscan.com/address/0xEB76995AcBb7EcB7255cDcfFc0c991ebe7cd3A5a" target="_blank" rel="noopener noreferrer">0xEB76995AcBb7EcB7255cDcfFc0c991ebe7cd3A5a</a></td>
          </tr>
          <tr>
            <td>Contract Language</td>
            <td>Solidity</td>
          </tr>
          <tr>
            <td>Current Buy Fee</td>
            <td>5%</td>
          </tr>
          <tr>
            <td>Current Sell Fee</td>
            <td>5%</td>
          </tr>
          <tr>
            <td>Current Transfer Fee</td>
            <td>0%</td>
          </tr>
        </tbody>
      </table>
</h5>

</> : ""}



{page === 7? <>
  <h4 className="text-white">
  <center> <img src={babyBob} alt="logo" className="img-fluid" width={300}/></center>
  <table className="table">
        <tbody>
          <tr>
            <td>AUDITED BY</td>
            <td>Cypherex6009</td>
          </tr>
          <tr>
            <td>WEBSITE</td>
            <td><a href="http://babybob.io/" target="_blank" rel="noopener noreferrer">http://babybob.io/</a></td>
          </tr>
          <tr>
            <td>TWITTER</td>
            <td><a href="https://x.com/BabyBobCoin" target="_blank" rel="noopener noreferrer">https://x.com/BabyBobCoin</a></td>
          </tr>
          <tr>
            <td>TELEGRAM</td>
            <td><a href="https://t.me/BabyBobCoin" target="_blank" rel="noopener noreferrer">https://t.me/BabyBobCoin</a></td>
          </tr>
          <tr>
            <td>CATEGORY</td>
            <td>LOW RISK</td>
          </tr>
          <tr>
            <td>CHAIN</td>
            <td>BSC</td>
          </tr>
          <tr>
            <td>CONTRACT</td>
            <td><a href="https://bscscan.com/address/0x7FEb783d2672d41a79bFee4cEE81eB7f3f60AD78" target="_blank" rel="noopener noreferrer">0x7FEb783d2672d41a79bFee4cEE81eB7f3f60AD78</a></td>
          </tr>
          <tr>
            <td>SCORE</td>
            <td>90/100</td>
          </tr>
          <tr>
            <td>BACKDOOR</td>
            <td>NOT FOUND</td>
          </tr>
        </tbody>
      </table>



</h4>
<hr />
<h1><span className="fw-bold" style={{fontSize:"64px"}}>Disclaimer</span></h1>
<h5 className="text-white">

This audit exclusively pertains to the Smart Contract code located at the specified address. Cypherex operates as a third-party auditing company, specializing in audits tailored to client requests. As a professional auditing firm, our thorough examination focuses on identifying and addressing potential vulnerabilities, backdoors, and any elements indicative of scam scripts within the contract.
Therefore:
We are not financial advisors, and we do not form partnerships with the contract owners.
Operations and website administration are entirely the responsibility of the client.
We do not exert influence over client operations, including changes to the website or the closure of withdrawal functions. Clients retain the autonomy to make these decisions through the contract.
Any concerns regarding the projects themselves should be addressed directly to the project owners and not through CYPHEREX.
Investors are not obligated, coerced, or influenced in any way to invest in projects audited by CYPHEREX.
We do not assume responsibility for your funds or guarantee profits.
We strongly recommend that investors conduct their own research and acquire cryptocurrency experience before making investment decisions.
To report any scams, malpractices, or irregularities, please send a message via Telegram to @CYPHEREX6009 for the purpose of blacklisting

</h5>

<h1 className="fw-bold" style={{fontSize:"64px"}}>Summary</h1>
<h5 className="text-white">
This smart contract has been reviewed by cyhperex6009 and found no backdoor code. There is no malicious code that allows the owner (or other) to withdraw all funds from the smart contract.
The owner can enable the trade and not able to pause the buy and sell again. All the Code is Safe. 

</h5>


<h1><span className="fw-bold" style={{fontSize:"64px"}}>Issues and Vulnerabilities</span></h1>
<table className="table">
  <tbody>
<tr>
  <td>CRITICAL ISSUES</td>
  <td>(Critical, high severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>HIGH ISSUES</td>
  <td>(High, medium severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>ERROR WARNINGS</td>
  <td>(Medium, low severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>OPTIMIZATION</td>
  <td>(Low severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>RECOMMENDATIONS</td>
  <td>(Very low severity)</td>
  <td>0</td>
</tr>
  </tbody>
</table>


<h1><span className="fw-bold" style={{fontSize:"64px"}}>Contract Overview</span></h1>
<h5 className="text-white">
<table className="table">
  <tbody>
  <tr>
            <td>Minting</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Honeypot</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Enable Trading</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Pause Trading</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Max Wallet</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Blacklist</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Proxy</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Hidden Owner</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Max Tx</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Antibot System</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
  </tbody>
</table>
</h5>


<h1 className="fw-bold" style={{fontSize:"64px"}}>Token Information</h1>

<h5 className="text-white">

<table className="table">
        <tbody>
          <tr>
            <td>Token Name</td>
            <td>Baby Bob</td>
          </tr>
          <tr>
            <td>Token Symbol</td>
            <td>BABYBOB</td>
          </tr>
          <tr>
            <td>Token Decimal</td>
            <td>18</td>
          </tr>
          <tr>
            <td>Blockchain</td>
            <td>BSC</td>
          </tr>
          <tr>
            <td>Token Supply</td>
            <td>690000000000</td>
          </tr>
          <tr>
            <td>Contract Address</td>
            <td><a href="https://bscscan.com/address/0x7FEb783d2672d41a79bFee4cEE81eB7f3f60AD78" target="_blank" rel="noopener noreferrer">0x7FEb783d2672d41a79bFee4cEE81eB7f3f60AD78</a></td>
          </tr>
          <tr>
            <td>Contract Language</td>
            <td>Solidity</td>
          </tr>
          <tr>
            <td>Current Buy Fee</td>
            <td>5%</td>
          </tr>
          <tr>
            <td>Current Sell Fee</td>
            <td>5%</td>
          </tr>
          <tr>
            <td>Current Transfer Fee</td>
            <td>0%</td>
          </tr>
        </tbody>
      </table>
</h5>

</> : ""}



{page === 8? <>
  <h4 className="text-white">
  <center> <img src={privatePepe} alt="logo" className="img-fluid" width={300}/></center>
  <table className="table">
        <tbody>
          <tr>
            <td>AUDITED BY</td>
            <td>Cypherex6009</td>
          </tr>
          <tr>
            <td>WEBSITE</td>
            <td><a href="https://savingprivatepepe.wtf/" target="_blank" rel="noopener noreferrer">https://savingprivatepepe.wtf/</a></td>
          </tr>
          <tr>
            <td>TWITTER</td>
            <td><a href="https://twitter.com/savingpvtpepe" target="_blank" rel="noopener noreferrer">https://twitter.com/savingpvtpepe</a></td>
          </tr>
          <tr>
            <td>TELEGRAM</td>
            <td><a href="https://t.me/SPepePortal" target="_blank" rel="noopener noreferrer">https://t.me/SPepePortal</a></td>
          </tr>
          <tr>
            <td>CATEGORY</td>
            <td>LOW RISK</td>
          </tr>
          <tr>
            <td>CHAIN</td>
            <td>BSC</td>
          </tr>
          <tr>
            <td>CONTRACT</td>
            <td><a href="https://bscscan.com/address/0x3C11c6065A50c8C40d73980A11E87a7935db7804" target="_blank" rel="noopener noreferrer">0x3C11c6065A50c8C40d73980A11E87a7935db7804</a></td>
          </tr>
          <tr>
            <td>SCORE</td>
            <td>97/100</td>
          </tr>
          <tr>
            <td>BACKDOOR</td>
            <td>NOT FOUND</td>
          </tr>
        </tbody>
      </table>



</h4>
<hr />
<h1><span className="fw-bold" style={{fontSize:"64px"}}>Disclaimer</span></h1>
<h5 className="text-white">

This audit exclusively pertains to the Smart Contract code located at the specified address. Cypherex operates as a third-party auditing company, specializing in audits tailored to client requests. As a professional auditing firm, our thorough examination focuses on identifying and addressing potential vulnerabilities, backdoors, and any elements indicative of scam scripts within the contract.
Therefore:
We are not financial advisors, and we do not form partnerships with the contract owners.
Operations and website administration are entirely the responsibility of the client.
We do not exert influence over client operations, including changes to the website or the closure of withdrawal functions. Clients retain the autonomy to make these decisions through the contract.
Any concerns regarding the projects themselves should be addressed directly to the project owners and not through CYPHEREX.
Investors are not obligated, coerced, or influenced in any way to invest in projects audited by CYPHEREX.
We do not assume responsibility for your funds or guarantee profits.
We strongly recommend that investors conduct their own research and acquire cryptocurrency experience before making investment decisions.
To report any scams, malpractices, or irregularities, please send a message via Telegram to @CYPHEREX6009 for the purpose of blacklisting

</h5>

<h1 className="fw-bold" style={{fontSize:"64px"}}>Summary</h1>
<h5 className="text-white">
This smart contract has been reviewed by cyhperex6009 and found no backdoor code. There is no malicious code that allows the owner (or other) to withdraw all funds from the smart contract.
The owner can enable the trade and not able to pause the buy and sell again. All the Code is Safe. 

</h5>


<h1><span className="fw-bold" style={{fontSize:"64px"}}>Issues and Vulnerabilities</span></h1>
<table className="table">
  <tbody>
<tr>
  <td>CRITICAL ISSUES</td>
  <td>(Critical, high severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>HIGH ISSUES</td>
  <td>(High, medium severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>ERROR WARNINGS</td>
  <td>(Medium, low severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>OPTIMIZATION</td>
  <td>(Low severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>RECOMMENDATIONS</td>
  <td>(Very low severity)</td>
  <td>0</td>
</tr>
  </tbody>
</table>


<h1><span className="fw-bold" style={{fontSize:"64px"}}>Contract Overview</span></h1>
<h5 className="text-white">
<table className="table">
  <tbody>
  <tr>
            <td>Minting</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Honeypot</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Enable Trading</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Pause Trading</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Max Wallet</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Blacklist</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Proxy</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Hidden Owner</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Max Tx</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Antibot System</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
  </tbody>
</table>
</h5>


<h1 className="fw-bold" style={{fontSize:"64px"}}>Token Information</h1>

<h5 className="text-white">

<table className="table">
        <tbody>
          <tr>
            <td>Token Name</td>
            <td>PRIVATE PEPE</td>
          </tr>
          <tr>
            <td>Token Symbol</td>
            <td>PVTPEPE</td>
          </tr>
          <tr>
            <td>Token Decimal</td>
            <td>18</td>
          </tr>
          <tr>
            <td>Blockchain</td>
            <td>BSC</td>
          </tr>
          <tr>
            <td>Token Supply</td>
            <td>1000000000</td>
          </tr>
          <tr>
            <td>Contract Address</td>
            <td><a href="https://bscscan.com/address/0x3C11c6065A50c8C40d73980A11E87a7935db7804" target="_blank" rel="noopener noreferrer">0x3C11c6065A50c8C40d73980A11E87a7935db7804</a></td>
          </tr>
          <tr>
            <td>Contract Language</td>
            <td>Solidity</td>
          </tr>
          <tr>
            <td>Current Buy Fee</td>
            <td>0%</td>
          </tr>
          <tr>
            <td>Current Sell Fee</td>
            <td>0%</td>
          </tr>
          <tr>
            <td>Current Transfer Fee</td>
            <td>0%</td>
          </tr>
        </tbody>
      </table>
</h5>

</> : ""}




{page === 9? <>
  <h4 className="text-white">
  <center> <img src={bobminer} alt="logo" width={300} className="img-fluid"/></center>
  <table className="table">
        <tbody>
          <tr>
            <td>AUDITED BY</td>
            <td>Cypherex6009</td>
          </tr>
          <tr>
            <td>WEBSITE</td>
            <td><a href="https://bobminer.app/" target="_blank" rel="noopener noreferrer">https://bobminer.app/</a></td>
          </tr>
          <tr>
            <td>TWITTER</td>
            <td><a href="https://twitter.com/daddybobminer" target="_blank" rel="noopener noreferrer">https://twitter.com/daddybobminer</a></td>
          </tr>
          <tr>
            <td>TELEGRAM</td>
            <td><a href="https://t.me/daddybobminer" target="_blank" rel="noopener noreferrer">https://t.me/daddybobminer</a></td>
          </tr>
          <tr>
            <td>CATEGORY</td>
            <td>HIGH RISK</td>
          </tr>
          <tr>
            <td>CHAIN</td>
            <td>BSC</td>
          </tr>
          <tr>
            <td>CONTRACT</td>
            <td><a href="https://bscscan.com/address/0xb9a6b73094ecccd06a98469cc3bb82709efd6cd2" target="_blank" rel="noopener noreferrer">0xB9a6B73094eCcCD06A98469cc3bb82709EFD6cd2</a></td>
          </tr>
          <tr>
            <td>SCORE</td>
            <td>70/100</td>
          </tr>
          <tr>
            <td>BACKDOOR</td>
            <td>NOT FOUND</td>
          </tr>
        </tbody>
      </table>



</h4>
<hr />
<h1><span className="fw-bold" style={{fontSize:"64px"}}>Disclaimer</span></h1>
<h5 className="text-white">

This audit exclusively pertains to the Smart Contract code located at the specified address. Cypherex operates as a third-party auditing company, specializing in audits tailored to client requests. As a professional auditing firm, our thorough examination focuses on identifying and addressing potential vulnerabilities, backdoors, and any elements indicative of scam scripts within the contract.
Therefore:
We are not financial advisors, and we do not form partnerships with the contract owners.
Operations and website administration are entirely the responsibility of the client.
We do not exert influence over client operations, including changes to the website or the closure of withdrawal functions. Clients retain the autonomy to make these decisions through the contract.
Any concerns regarding the projects themselves should be addressed directly to the project owners and not through CYPHEREX.
Investors are not obligated, coerced, or influenced in any way to invest in projects audited by CYPHEREX.
We do not assume responsibility for your funds or guarantee profits.
We strongly recommend that investors conduct their own research and acquire cryptocurrency experience before making investment decisions.
To report any scams, malpractices, or irregularities, please send a message via Telegram to @CYPHEREX6009 for the purpose of blacklisting

</h5>

<h1 className="fw-bold">Summary</h1>
<h5 className="text-white">
This smart contract has been reviewed by cyhperex6009 and found no backdoor code. There is no malicious code that allows the owner (or other) to withdraw all funds from the smart contract.
Deposit to earn up to 3% daily ROI. Unlock levels through referrals, earning a percentage from their investments. Enjoy a 5% direct referral bonus. Audit verified for security. Start earning with confidence!"
<br /><span className="fw-bold">Owner Privileges:</span> The contract owner has the authority to both stop and open the withdrawal function.
</h5>


<h1><span className="fw-bold" style={{fontSize:"64px"}}>Issues and Vulnerabilities</span></h1>
<table className="table">
  <tbody>
<tr>
  <td>CRITICAL ISSUES</td>
  <td>(Critical, high severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>HIGH ISSUES</td>
  <td>(High, medium severity)</td>
  <td>1</td>
</tr>

<tr>
  <td>ERROR WARNINGS</td>
  <td>(Medium, low severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>OPTIMIZATION</td>
  <td>(Low severity)</td>
  <td>0</td>
</tr>

<tr>
  <td>RECOMMENDATIONS</td>
  <td>(Very low severity)</td>
  <td>0</td>
</tr>
  </tbody>
</table>
<h1><span className="fw-bold" style={{fontSize:"64px"}}>High ISSUE</span></h1>
<h3 className="text-white fw-bold">The system employed operates on a principle of Return on Investment (ROI) and is inherently HIGH-RISK. Principal deposits made by users are non-withdrawable, with dividends and referral commissions being the only accessible returns. Dividends are funded from deposits made by other users. Exercise caution and conduct thorough research before making any investments to ensure informed decision-making.</h3>

<h1><span className="fw-bold" style={{fontSize:"64px"}}>Contract Overview</span></h1>
<h5 className="text-white">

<table className="table">
     
        <tbody>
          <tr>
            <td>Withdraw Funds By Owner</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>BOT PROTECTION</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>YES</span>
            </td>
          </tr>
        
          <tr>
            <td>Pause Deposit</td>
            <td>
              <span style={{ color: 'green' }}>🔴</span>
            </td>
            <td>
              <span style={{ color: 'red' }}>YES</span>
            </td>
          </tr>
          <tr>
            <td>Renounced</td>
            <td>
              <span style={{ color: 'green' }}>🔴</span>
            </td>
            <td>
              <span style={{ color: 'red' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Blacklist</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Proxy</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Hidden Owner</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Reentrancy</td>
            <td>
              <span style={{ color: 'green' }}>🔴</span>
            </td>
            <td>
              <span style={{ color: 'red' }}>NO</span>
            </td>
          </tr>
          <tr>
            <td>Antibot System</td>
            <td>
              <span style={{ color: 'green' }}>🟢</span>
            </td>
            <td>
              <span style={{ color: 'green' }}>NO</span>
            </td>
          </tr>
        </tbody>
      </table>
</h5>


<h1 className="fw-bold" style={{fontSize:"64px"}}>Token Information</h1>

<h5 className="text-white">

<table className="table">
        <tbody>
          <tr>
            <td>Project Name</td>
            <td>BobMiner</td>
          </tr>
          <tr>
            <td>Daily ROI</td>
            <td>3%</td>
          </tr>
       
          <tr>
            <td>Blockchain</td>
            <td>BSC</td>
          </tr>
       
          <tr>
            <td>Contract Address</td>
            <td><a href="https://bscscan.com/address/0xb9a6b73094ecccd06a98469cc3bb82709efd6cd2" target="_blank" rel="noopener noreferrer">0xB9a6B73094eCcCD06A98469cc3bb82709EFD6cd2</a></td>
          </tr>
          <tr>
            <td>Contract Language</td>
            <td>Solidity</td>
          </tr>
          <tr>
            <td>Current Buy Fee</td>
            <td>5%</td>
          </tr>
          <tr>
            <td>Current Sell Fee</td>
            <td>5%</td>
          </tr>
          <tr>
            <td>Referral Fee</td>
            <td>5%</td>
          </tr>
        </tbody>
      </table>
</h5>

</> : ""}



{page === -1? <>
  <h1 className="fw-bold text-center">GET YOUR AUDIT TODAY</h1>
  <h5 className="text-white text-center">
CONTACT: TG = https://t.me/Cypherex6009
</h5>
 </> : ""}


 {page === -2? <>
  <h1 className="fw-bold text-center">KYC WITH CYPHEREX</h1>
  <h5 className="text-white text-center">
CONTACT: TG = https://t.me/Cypherex6009
</h5>
 </> : ""}

 <br />

{page === 0 || page === -1 || page === -2? <>
  <h1 className="fw-bold text-center" style={{fontSize:"64px"}}>Blockchain Development</h1>
<br />
 <div className="row">
  <div className="col-sm-4">
     <h1 className="fw-bold text-center">
     dApp Development
     </h1>
     <p className="text-white text-center">
     Our team is skilled at developing decentralized applications (dApps) that offer seamless user experiences and harness the full potential of blockchain technology. From decentralized finance (DeFi) platforms to NFT marketplaces, our developers can create custom dApps that cater to a wide range of use cases and industries.
     </p>
  </div>

  <div className="col-sm-4">
  <h1 className="fw-bold text-center">
  Smart Contract Development
     </h1>
     <p className="text-white text-center">
     Trust our highly experienced developers to create secure and efficient smart contracts for your blockchain project. We possess the expertise to develop custom smart contracts for a wide range of platforms and use cases, ensuring seamless integration, deployment, and optimal performance across different blockchain networks.  </p>
  </div>

  <div className="col-sm-4">
  <h1 className="fw-bold text-center">
  Blockchain Infrastructure Development
     </h1>
     <p className="text-white text-center">
     We offer end-to-end blockchain infrastructure development services, including the design and implementation of custom blockchain networks, consensus mechanisms, and security protocols. Our team can help you build scalable, secure, and efficient blockchain solutions tailored to your unique needs. </p>
  </div>
 </div>

 <br />


 <h1 className="fw-bold text-center" style={{fontSize:"64px"}}>Explore Our Offerings</h1>
<br />
 <div className="row">
  <div className="col-sm-4">
     <h1 className="fw-bold">
     Blockchain Audit
     </h1>
     <p className="text-white">
     Our team of experts meticulously examines every aspect of your project, from dApps to smart contracts, for potential vulnerabilities, logic flaws, and inefficiencies.
<br />
 ~ dApp Audit
<br /> ~ Smart Contract Audit
<br />~ Protocol Audit
    </p>
  </div>

  <div className="col-sm-4">
  <h1 className="fw-bold">
  Blockchain Development
     </h1>
     <p className="text-white">
  
Our developers have extensive experience in creating secure and efficient blockchain solutions tailored to your specific requirements. .

<br /> ~ dApp Development
<br /> ~ Smart Contract Development
<br /> ~ Blockchain Infrastructure Development
   </p>
     </div>

  <div className="col-sm-4">
  <h1 className="fw-bold">
  Blockchain Consulting
     </h1>
     <p className="text-white">
     Our team provides expert guidance to navigate the complex blockchain ecosystem, offering personalized consulting services tailored to your business needs
<br />
<br /> ~ Platform Selection Assistance
<br /> ~ Implementation Support
<br /> ~ Blockchain Infrastructure Optimization
    </p>
  </div>
 </div>

</> : ""}
 
<br />
 <h1>All Rights Reserved 2023</h1>
</div>
   </>
  );
}

export default App;
